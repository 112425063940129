<template>
  <div>
    <h1 class="title has-text-centered">Section Test</h1>
    <h1 class="title is-size-4"><b>CASE STUDY</b></h1>
    <span class="columns is-multiline">
      <div class="column is-12">
        <div class="columns is-12 section-type">
          <div class="column is-2">
            <b>Question</b>
          </div>
          <div class="column is-10">
            <ValidationProvider
              :vid="`sectionQuestion`"
              name="subsection"
              :rules="`required`"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input v-model="data.section[0].question"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="columns is-12">
          <div class="column is-2">
            <b>Content</b>
          </div>
          <div class="column is-10">
            <ValidationProvider
              :vid="`sectionContent`"
              name="subsection"
              :rules="`required`"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <ckeditor
                  v-model="data.section[0].content"
                  :config="editorConfig"
                />
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <div
        class="column is-12"
        v-for="(subsection, index) in data.subsection"
        :key="index"
      >
        <div class="columns is-12 subsection-type section-type">
          <div class="column is-2">
            <b>Title</b>
          </div>
          <div class="column is-10">
            <ValidationProvider
              :vid="`subsectionTitle` + index"
              name="subsection"
              :rules="`required`"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input v-model="subsection.title"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-2">
            <b>Content</b>
          </div>
          <div class="column is-10">
            <ValidationProvider
              :vid="`subsectionQuestion` + index"
              name="subsection"
              :rules="`required`"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <ckeditor
                  v-model="subsection.question"
                  :config="editorConfig"
                />
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="column is-12">
            <b-field grouped position="is-right">
              <b-button
                class="trash"
                @click="deletePopup(index)"
                icon-left="trash"
              ></b-button>
            </b-field>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <b-field>
          <b-button
            icon-left="plus"
            class="btn-add-section"
            @click="addSection()"
          >
            add new section
          </b-button>
        </b-field>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
  data() {
    return {
      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: 'div[class]',
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: 'justify,font',
      },
    }
  },
  methods: {
    addSection() {
      let section = {
        title: '',
        question: '',
      }
      this.data.subsection.push(section)
      this.success('section berhasil ditambah')
    },
    deletePopup(index) {
      this.$buefy.dialog.confirm({
        title: 'Delete Question',
        message: `Are you sure want to delete this question?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteAssessment(index),
      })
    },
    deleteAssessment(index) {
      if (this.data.subsection.length > 1) {
        this.data.subsection.splice(index, 1)
        this.success('successfully deleted the question')
      } else {
        this.danger('section must have at least 1 question')
      }
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },
}
</script>
<style>
.section-type {
  margin-bottom: 0 !important;
}

.subsection-type {
  padding-top: 1% !important;
}

.btn-add-section {
  border: none !important;
  color: #00a3a3;
  background-color: whitesmoke;
  font-weight: 600;
}

.trash {
  color: red;
}
.btn-add-section:hover {
  border: none !important;
  color: #00a3a3;
  background-color: whitesmoke;
  font-weight: 600;
}
.btn-add-section:focus:not(:active) {
  box-shadow: none !important;
}
.btn-add-section:focus,
.btn-add-section.is-focused {
  color: #00a3a3 !important;
  background-color: whitesmoke;
}
</style>
