var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('section',{ref:"element",class:{ main: true, index: _vm.activeStep == 1 }},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.savePopup)}}},[_c('div',[(!_vm.isLoading)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column bar"},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: "caseStudy" }}},[_c('h3',{staticClass:"is-size-4"},[_vm._v("CASE STUDY")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: "showCaseStudy" }}},[_c('h3',{staticClass:"is-size-4"},[_vm._v("DETAIL")])])],1),_c('li',[_c('h3',{staticClass:"is-size-4 breadcrumb-item"},[(_vm.$route.name == 'addCaseStudySection')?[_vm._v("ADD SECTION")]:(_vm.$route.name == 'editCaseStudySection')?[_vm._v("EDIT SECTION")]:_vm._e()],2)])])])])]):_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('b-steps',{attrs:{"animated":_vm.isAnimated,"has-navigation":true},scopedSlots:_vm._u([{key:"navigation",fn:function(ref){
var previous = ref.previous;
var next = ref.next;
return [(_vm.activeStep != 0)?_c('b-button',{staticClass:"prev",attrs:{"type":"is-hcc","disabled":previous.disabled},on:{"click":function($event){$event.preventDefault();return previous.action.apply(null, arguments)}}},[_vm._v("Prev")]):_vm._e(),(_vm.activeStep == 0)?_c('b-button',{staticClass:"button-next",attrs:{"type":"is-hcc","disabled":next.disabled || !_vm.introduction.description},on:{"click":function($event){$event.preventDefault();return next.action.apply(null, arguments)}}},[_vm._v("Next")]):_vm._e(),(_vm.activeStep == 1)?_c('b-button',{staticClass:"button-next",attrs:{"type":"is-hcc","disabled":next.disabled ||
                      !_vm.rules.rulesName ||
                      _vm.rules.time == 0 ||
                      _vm.rules.screenCaptureTime == 0 ||
                      _vm.rules.candidatPhotoTime == 0},on:{"click":function($event){$event.preventDefault();return next.action.apply(null, arguments)}}},[_vm._v("Next")]):(_vm.activeStep == 2)?_c('b-button',{staticClass:"button-next",attrs:{"type":"is-hcc","disabled":next.disabled},on:{"click":function($event){$event.preventDefault();return next.action.apply(null, arguments)}}},[_vm._v("Next")]):(_vm.activeStep == 3)?_c('b-button',{staticClass:"button-next",attrs:{"type":"is-hcc","native-type":"submit"}},[_vm._v("Save")]):_vm._e()]}}],null,true),model:{value:(_vm.activeStep),callback:function ($$v) {_vm.activeStep=$$v},expression:"activeStep"}},[_c('b-step-item',{staticClass:"step",attrs:{"label":"Introduction","icon":"envelope","clickable":_vm.$route.name == 'editCaseStudySection' ? true : false,"type":{ 'is-hcc': _vm.isProfileSuccess }}},[_c('SectionIntroduction',{attrs:{"data":_vm.introduction}})],1),_c('b-step-item',{staticClass:"step",attrs:{"label":"Rules","icon":"pencil-ruler","clickable":_vm.$route.name == 'editCaseStudySection' ? true : false,"type":{ 'is-hcc': _vm.isProfileSuccess }}},[_c('SectionRules',{attrs:{"data":_vm.rules}})],1),_c('b-step-item',{staticClass:"step",attrs:{"label":"Introduction Test","icon":"flag","clickable":_vm.$route.name == 'editCaseStudySection' ? true : false,"type":{ 'is-hcc': _vm.isProfileSuccess }}},[_c('SectionIntroductionTest',{attrs:{"data":_vm.caseStudyIntroductionTest}})],1),_c('b-step-item',{staticClass:"step",attrs:{"label":"Question Test","icon":"flag","clickable":_vm.$route.name == 'editCaseStudySection' ? true : false,"type":{ 'is-hcc': _vm.isProfileSuccess }}},[_c('SectionQuestionTest',{attrs:{"data":_vm.caseStudyQuestionTest}})],1)],1)],1)])])])]}}],null,false,4061798663)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }