<template>
  <section
    v-bind:class="{ main: true, index: activeStep == 1 }"
    v-if="!isLoading"
    ref="element"
  >
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form @submit.prevent="handleSubmit(savePopup)">
        <div>
          <div class="columns" v-if="!isLoading">
            <div class="column bar">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <router-link :to="{ name: `caseStudy` }">
                      <h3 class="is-size-4">CASE STUDY</h3>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: `showCaseStudy` }">
                      <h3 class="is-size-4">DETAIL</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3 class="is-size-4 breadcrumb-item">
                      <template v-if="$route.name == 'addCaseStudySection'"
                        >ADD SECTION</template
                      >
                      <template
                        v-else-if="$route.name == 'editCaseStudySection'"
                        >EDIT SECTION</template
                      >
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <!-- <div class="column bar">
              <b-field grouped position="is-right">
                <b-button
                  class="is-hcc"
                  native-type="submit"
                  v-if="activeStep == 3"
                  >Save</b-button
                >
              </b-field>
            </div> -->
          </div>
          <div class="columns">
            <div class="column is-12">
              <b-steps
                v-model="activeStep"
                :animated="isAnimated"
                :has-navigation="true"
              >
                <!-- For Introduction -->
                <b-step-item
                  class="step"
                  label="Introduction"
                  icon="envelope"
                  :clickable="
                    $route.name == 'editCaseStudySection' ? true : false
                  "
                  :type="{ 'is-hcc': isProfileSuccess }"
                >
                  <SectionIntroduction :data="introduction" />
                </b-step-item>

                <!-- For Introduction -->
                <b-step-item
                  class="step"
                  label="Rules"
                  icon="pencil-ruler"
                  :clickable="
                    $route.name == 'editCaseStudySection' ? true : false
                  "
                  :type="{ 'is-hcc': isProfileSuccess }"
                >
                  <SectionRules :data="rules" />
                </b-step-item>

                <b-step-item
                  class="step"
                  label="Introduction Test"
                  icon="flag"
                  :clickable="
                    $route.name == 'editCaseStudySection' ? true : false
                  "
                  :type="{ 'is-hcc': isProfileSuccess }"
                >
                  <SectionIntroductionTest :data="caseStudyIntroductionTest" />
                </b-step-item>

                <b-step-item
                  class="step"
                  label="Question Test"
                  icon="flag"
                  :clickable="
                    $route.name == 'editCaseStudySection' ? true : false
                  "
                  :type="{ 'is-hcc': isProfileSuccess }"
                >
                  <SectionQuestionTest :data="caseStudyQuestionTest" />
                </b-step-item>

                <!-- For pagination -->
                <template slot="navigation" slot-scope="{ previous, next }">
                  <b-button
                    type="is-hcc"
                    class="prev"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action"
                    v-if="activeStep != 0"
                    >Prev</b-button
                  >

                  <!-- Next button in introduction tab -->
                  <b-button
                    type="is-hcc"
                    @click.prevent="next.action"
                    v-if="activeStep == 0"
                    class="button-next"
                    :disabled="next.disabled || !introduction.description"
                    >Next</b-button
                  >

                  <!-- Next button in rules tab -->
                  <b-button
                    type="is-hcc"
                    @click.prevent="next.action"
                    class="button-next"
                    v-if="activeStep == 1"
                    :disabled="
                      next.disabled ||
                        !rules.rulesName ||
                        rules.time == 0 ||
                        rules.screenCaptureTime == 0 ||
                        rules.candidatPhotoTime == 0
                    "
                    >Next</b-button
                  >

                  <!-- Next button in assesment introduction tab -->
                  <b-button
                    type="is-hcc"
                    @click.prevent="next.action"
                    class="button-next"
                    v-else-if="activeStep == 2"
                    :disabled="next.disabled"
                    >Next</b-button
                  >

                  <!-- Else -->
                  <b-button
                    type="is-hcc"
                    native-type="submit"
                    v-else-if="activeStep == 3"
                    class="button-next"
                    >Save</b-button
                  >
                </template>
              </b-steps>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import SectionIntroduction from "@/components/SectionForm/SectionIntroduction";
import SectionRules from "@/components/SectionForm/SectionRules";
import SectionIntroductionTest from "@/components/SectionForm/SectionIntroductionTest";
import SectionQuestionTest from "@/components/CaseStudySection/SectionQuestionTest";

export default {
  components: {
    SectionIntroduction,
    SectionRules,
    SectionIntroductionTest,
    SectionQuestionTest
  },
  data() {
    return {
      // For Active Step
      activeStep: 0,

      // For Animated Step
      isAnimated: true,

      // When step is success
      isProfileSuccess: true,

      // FOr loading page
      isLoading: false,

      // For Introduction
      introduction: {
        title: "",
        description: ""
      },

      // For Rules Data
      rules: {
        rulesName: "",
        time: 0,
        screenCaptureTime: 10,
        candidatPhotoTime: 10
      },

      // For Assessment Introduction Test
      caseStudyIntroductionTest: {
        description: ""
      },

      // For Assessment Question Test
      caseStudyQuestionTest: {
        section: [
          {
            title: "",
            question: ""
          }
        ],
        subsection: [
          {
            title: "",
            question: ""
          }
        ]
      },
      isEditing: false
    };
  },
  created() {
    if (this.$route.name == "editCaseStudySection") {
      this.fetchData();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const response = await this.$store.dispatch(
        "getCaseStudySection",
        this.$route.params.caseStudySectionId
      );
      this.introduction = response.introduction;
      this.rules = response.rules;
      this.caseStudyIntroductionTest = response.caseStudyIntroductionTest;
      this.caseStudyQuestionTest = response.caseStudyQuestionTest;

      this.isLoading = false;
    },

    // For save alert
    savePopup() {
      this.$buefy.dialog.confirm({
        title: "Save Case Study",
        message: `Are you sure want to save this case study?`,
        cancelText: "No, cancel it!",
        confirmText: "Yes, save it!",
        type: "is-success",
        onConfirm: () => this.createSection()
      });
    },

    // For save section
    async createSection() {
      // this.$refs.form.validate().then(success => {
      //   if (!success) {
      //     this.danger("please fill all form");
      //   } else {
      //     this.success("save success");
      //   }
      // });

      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage ? null : this.$refs.element.$el
      });
      if (this.$route.name == "addCaseStudySection") {
        await this.$store
          .dispatch("createCaseStudySection", {
            id: this.$route.params.caseStudyId,
            introduction: this.introduction,
            rules: this.rules,
            caseStudyIntroductionTest: this.caseStudyIntroductionTest,
            caseStudyQuestionTest: this.caseStudyQuestionTest
          })
          .then(response => {
            if (response.status == 200) {
              this.isEditing = true;
              this.success("Successfully create section");
              loadingComponent.close();
              this.$router.push({ name: "showCaseStudy" });
            } else {
              this.danger("Failed to create section");
            }
          })
          .catch(() => {
            this.danger("Failed to create a new section");
            loadingComponent.close();
          });
      } else {
        await this.$store
          .dispatch("updateCaseStudySection", {
            id: this.$route.params.caseStudySectionId,
            introduction: this.introduction,
            rules: this.rules,
            caseStudyIntroductionTest: this.caseStudyIntroductionTest,
            caseStudyQuestionTest: this.caseStudyQuestionTest
          })
          .then(response => {
            if (response.status == 200) {
              this.isEditing = true;
              this.success("Successfully update section");
              loadingComponent.close();
              this.$router.push({ name: "showCaseStudy" });
            } else {
              this.danger("Failed to update section");
            }
          })
          .catch(() => {
            this.danger("Failed to update section");
            loadingComponent.close();
          });
      }
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing == false) {
      const answer = window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
<style>
.prev {
  margin-left: 1.5%;
}

.button-next {
  margin-right: 1.5%;
}
.step {
  margin-top: 3%;
}
</style>
