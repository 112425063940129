var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title has-text-centered"},[_vm._v("Section Test")]),_vm._m(0),_c('span',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns is-12 section-type"},[_vm._m(1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"sectionQuestion","name":"subsection","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{model:{value:(_vm.data.section[0].question),callback:function ($$v) {_vm.$set(_vm.data.section[0], "question", $$v)},expression:"data.section[0].question"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"columns is-12"},[_vm._m(2),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"sectionContent","name":"subsection","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.data.section[0].content),callback:function ($$v) {_vm.$set(_vm.data.section[0], "content", $$v)},expression:"data.section[0].content"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_vm._l((_vm.data.subsection),function(subsection,index){return _c('div',{key:index,staticClass:"column is-12"},[_c('div',{staticClass:"columns is-12 subsection-type section-type"},[_vm._m(3,true),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"subsectionTitle" + index,"name":"subsection","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{model:{value:(subsection.title),callback:function ($$v) {_vm.$set(subsection, "title", $$v)},expression:"subsection.title"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"columns is-multiline"},[_vm._m(4,true),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"subsectionQuestion" + index,"name":"subsection","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(subsection.question),callback:function ($$v) {_vm.$set(subsection, "question", $$v)},expression:"subsection.question"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"grouped":"","position":"is-right"}},[_c('b-button',{staticClass:"trash",attrs:{"icon-left":"trash"},on:{"click":function($event){return _vm.deletePopup(index)}}})],1)],1)])])}),_c('div',{staticClass:"column is-12"},[_c('b-field',[_c('b-button',{staticClass:"btn-add-section",attrs:{"icon-left":"plus"},on:{"click":function($event){return _vm.addSection()}}},[_vm._v(" add new section ")])],1)],1)],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"title is-size-4"},[_c('b',[_vm._v("CASE STUDY")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Question")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Content")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Title")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Content")])])}]

export { render, staticRenderFns }