<template>
  <div>
    <h1 class="title has-text-centered">Rules</h1>
    <span>
      <!-- For Rules Name Field -->
      <div class="columns is-12">
        <div class="column is-3">
          <b>Rules Name</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-9">
          <div class="columns">
            <div class="column is-4">
              <b-input v-model="data.rulesName"></b-input>
            </div>
          </div>
        </div>
      </div>
      <!-- For time -->
      <div class="columns is-multiline">
        <div class="column is-3">
          <b>Duration Test</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-9">
          <div class="columns">
            <div class="column is-4">
              <b-input v-model="data.time" type="number" min="0"></b-input>
            </div>
            <div class="column is-2">Detik</div>
          </div>
        </div>
        <div class="column is-3">
          <b>Interval Screen Capture</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-9">
          <div class="columns">
            <div class="column is-4">
              <b-input
                v-model="data.screenCaptureTime"
                type="number"
                min="10"
              ></b-input>
            </div>
            <div class="column is-2">Detik</div>
          </div>
        </div>
        <div class="column is-3">
          <b>Interval Take Candidate Photo</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-9">
          <div class="columns">
            <div class="column is-4">
              <b-input
                v-model="data.candidatPhotoTime"
                type="number"
                min="10"
              ></b-input>
            </div>
            <div class="column is-2">Detik</div>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  },
  data() {
    return {
      questionTypeList: ["Essay", "Multiple Choice", "Other"]
    };
  },
  methods: {
    testGanti() {
      this.$emit("clicked");
    },
    selectedQuestion(type) {
      if (!this.data.typePoint.includes(type)) {
        this.data.typePoint.push(type);
      } else {
        let index = this.data.typePoint.indexOf(type);
        this.data.typePoint.splice(index, 1);
      }
    },
    addMoreRulesPoint() {
      this.data.rankingPointCategory.push({
        title: "",
        point: 0
      });
    },
    deleteRulesPoint(index) {
      this.data.rankingPointCategory.splice(index, 1);
    }
  },
  watch: {
    "data.questionType": function() {
      this.data.typePoint = [];
    },
    "data.checkboxTypeAnswer": function() {
      this.data.checkboxMinAnswer = 1;
      this.data.checkboxMaxAnswer = 1;
    },
    "data.rankingTypeAnswer": function() {
      this.data.rankingMinAnswer = 1;
      this.data.rankingMaxAnswer = 1;
    },
    "data.typePoint": function() {
      if (this.data.typePoint.includes("question")) {
        // this.data.randomType = "Random Option";
        this.data.randomType[0] = false;
        this.data.randomQuestionNumber = 0;
      }
    }
  }
};
</script>
